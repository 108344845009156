export const errorMessages = {
  timeOut: `Ошибка подключения`,
  wrongLoginData: `Неправильный пароль или e-mail!`,
  emptyField: `Заполните все поля!`,
  differentPasswords: `Поле "Новый пароль" и "Повторите пороль" должны быть одиноковы.`,
  serverError: `Внутренняя ошибка сервера`,
  userIsAlreadyLoggedIn:
    "В данный момент этот аккаунт используется для работы." +
    " Чтобы войти в аккаунт обратитесь к администратору",
};

export const ROLES = {
  ADMIN: "admin",
  MANAGER: "manager",
  REPORTING: "reporting",
};
export const BEGINNING_OF_THE_ENG_ALPHABET_IN_ASCII = 65;

export const DB_FIELDS = [
  {
    displayText: "Имя",
    name: "first_name",
  },
  {
    displayText: "Отчество",
    name: "surname",
  },
  {
    displayText: "Фамилия",
    name: "last_name",
  },
  {
    displayText: "ФИО",
    name: "fullname",
  },
  {
    displayText: "Телефон",
    name: "phone",
  },
  {
    displayText: "@Телеграм",
    name: "telegram_link",
  },
  {
    displayText: "Область",
    name: "region",
  },
  {
    displayText: "Город",
    name: "city",
  },
  {
    displayText: "Адрес",
    name: "address",
  },
  {
    displayText: "Возраст",
    name: "age",
  },
  {
    displayText: "Часовой пояс",
    name: "timezone",
  },
  {
    displayText: "Доп. поле",
    name: "additional_field1",
  },
  {
    displayText: "Доп. дата",
    name: "additional_date",
  },
  {
    displayText: "ИНН",
    name: "tin",
  },
  {
    displayText: "СНИЛС",
    name: "snils",
  },
  {
    displayText: "Паспорт",
    name: "passport",
  },
];
const addBreaklines = (children) =>
  children.map((child, i) => [child, <br key={i} />]);

export const REDRAFT_REDERERS = {
  /**
   * Those callbacks will be called recursively to render a nested structure
   */
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
    ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    UNDERLINE: (children, { key }) => <u key={key}>{children}</u>,
    CODE: (children, { key }) => <span key={key}>{children}</span>,
  },
  /**
   * Blocks receive children and depth
   * Note that children are an array of blocks with same styling,
   */
  blocks: {
    unstyled: (children) => children.map((child, i) => <p key={i}>{child}</p>),
    blockquote: (children) => (
      <blockquote>{addBreaklines(children)}</blockquote>
    ),
    "header-one": (children) =>
      children.map((child, i) => <h1 key={i}>{child}</h1>),
    "header-two": (children) =>
      children.map((child, i) => <h2 key={i}>{child}</h2>),
    // You can also access the original keys of the blocks
    "code-block": (children, { keys }) => (
      <pre key={keys[0]}>{addBreaklines(children)}</pre>
    ),
    // or depth for nested lists
    "unordered-list-item": (children, { depth, keys }) => (
      <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
        {children.map((child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    ),
    "ordered-list-item": (children, { depth, keys }) => (
      <ol key={keys.join("|")} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ol>
    ),
    // If your blocks use meta data it can also be accessed like keys
  },
  /**
   * Entities receive children and the entity data
   */
  entities: {
    // key is the entity key value from raw
    LINK: (children, data, { key }) => (
      <a key={key} href={data.url}>
        {children}
      </a>
    ),
  },
  /**
   * Array of decorators,
   * Entities receive children and the entity data,
   * inspired by https://facebook.github.io/draft-js/docs/advanced-topics-decorators.html
   * it's also possible to pass a custom Decorator class that matches the [DraftDecoratorType](https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecoratorType.js)
   */
  decorators: [
    {
      // by default linkStrategy receives a ContentBlock stub (more info under Creating the ContentBlock)
      // strategy only receives first two arguments, contentState is yet not provided
      strategy: () => {},
      // component - a callback as with other renderers
      // decoratedText a plain string matched by the strategy
      // if your decorator depends on draft-js contentState you need to provide convertFromRaw in redraft options
      component: ({ children, decoratedText }) => (
        <a href={decoratedText}>{children}</a>
      ),
    },
  ],
};

export const FULL_EDITOR_TOOLBAR = {
  options: ["inline", "blockType", "fontSize", "textAlign"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline", "strikethrough"],
  },
  fontSize: {
    icon: undefined,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  blockType: {
    inDropdown: true,
    options: [
      "Normal",
      "H1",
      "H2",
      "H3",
      "H4",
      "H5",
      "H6",
      "Blockquote",
      "Code",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: [
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"],
  },
};

export const MAIN_EDITOR_TOOLBAR = {
  options: ["inline", "fontSize", "textAlign"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "superscript",
      "subscript",
    ],
  },
  fontSize: {
    icon: undefined,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"],
  },
};

export const ADDITIONAL_EDITOR_TOOLBAR = {
  options: [],
};

export const timezones = [
  { name: "+2 UTC", value: 2 },
  { name: "+3 UTC", value: 3 },
  { name: "+4 UTC", value: 4 },
  { name: "+5 UTC", value: 5 },
  { name: "+6 UTC", value: 6 },
  { name: "+7 UTC", value: 7 },
  { name: "+8 UTC", value: 8 },
  { name: "+9 UTC", value: 9 },
  { name: "+10 UTC", value: 10 },
  { name: "+11 UTC", value: 11 },
  { name: "+12 UTC", value: 12 },
];
export const NAME_OF_MONTHS = {
  0: "января",
  1: "февраля",
  2: "марта",
  3: "апреля",
  4: "мая",
  5: "июня",
  6: "июля",
  7: "августа",
  8: "сентября",
  9: "октября",
  10: "ноября",
  11: "декабря",
};

export const TINY_EDITOR_TOOLTIP =
  "undo redo | formatselect | " +
  "bold italic backcolor link | alignleft aligncenter " +
  "alignright alignjustify | bullist numlist outdent indent customIndent | " +
  "removeformat";

export const TINY_EDITOR_FONT_SIZE_FORMATS =
  "6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt";

export const TINY_EDITOR_FONTS =
  "Arial=arial; Arial Black=arial black; Book Antique=book antiqua; Comic Sans MS=comic sans ms;" +
  "Courier New=courier new; Georgia=georgia; Helvetica=helvetica; Tahoma=tahoma;" +
  "Times New Roman=times new roman; Trebuchet MS=trebuchet ms; Verdana=verdana";

export const TINY_EDITOR_LINE_HEIGHTS = "1 1.1 1.2 1.3 1.4 1.5 2 2.2";

export const REPORTING_DAYS_TYPES = {
  weekdays: "weekdays",
  weekends: "weekends",
};

// NOTE! Must match the date on the backend side
export const SEPARATING_REPORTING_STARTING_DATA = "2023-11-13";
