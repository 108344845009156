import React from 'react'
import {Watch} from 'react-loader-spinner';
import { usePromiseTracker } from "react-promise-tracker";


export default function OverlayAndSpinner() {
    const { promiseInProgress } = usePromiseTracker();

    return (
        <>
            {promiseInProgress &&
                <div className='overlay'>
                    <div className='loader-spinner-container'>
                        <Watch 
                            color="#00BFFF" 
                            height={60} 
                            width={60}
                        />
                    </div>
                </div>
            }
        </>
    )
}
